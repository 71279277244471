import React from "react";
import "../../src/utils/css/global.css";
function Pagination(props) {
  let pages = [];

  for (
    let i = 1;
    i <= Math.ceil(props.totalPosts.length / props.postPerPage);
    i++
  ) {
    pages.push(i);
  }
  return (
    <div className="pagination">
      {pages.map((item, index) => {
        return (
          <button
            className={item === props.currentPage ? "active" : ""}
            onClick={() => props.setCurrentPage(item)}
            key={index}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}

export default Pagination;
