import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "../../utils/css/global.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Alert as MuiAlert,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FaRegEdit, FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa";
import axios from "axios";
import "../../utils/css/global.css";
import Pagination from "../../components/Pagination";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw);
`;

function Products() {
  const [user, setUser] = useState([]);
  const [error, setError] = useState();
  const [response, setResponse] = useState();
  const [close, setClose] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPages, setPostPages] = useState(7);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/user/user-list");
    }
  }, [navigate]);

  const getUsers = async () => {
    await axios
      .get("https://heaths-fa5b9965979a.herokuapp.com/user")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const suspend = async (id) => {
    const isActive = {
      isActive: true,
    };
    await axios
      .put(`https://heaths-fa5b9965979a.herokuapp.com/user/${id}`, isActive)
      .then((res) => {
        setResponse(res.data.message);
        setClose(true);
        getUsers();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setClose(true);
        getUsers();
      });
  };

  const undosuspend = async (id) => {
    const isActive = {
      isActive: false,
    };
    await axios
      .put(`https://heaths-fa5b9965979a.herokuapp.com/user/${id}`, isActive)
      .then((res) => {
        setResponse(res.data.message);
        setClose(true);
        getUsers();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setClose(true);
        getUsers();
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  const deleteLoad = async (id) => {
    await axios
      .delete(`https://heaths-fa5b9965979a.herokuapp.com/user/${id}`)
      .then((res) => {
        setResponse(res.data.message);
        getUsers();
        setClose(true);
      })

      .catch((err) => {
        setError(err.response.data.message);
        setClose(true);
      });
  };

  const lastIndex = currentPage * postPages;
  const firstIndex = lastIndex - postPages;
  const slicePost = user.slice(firstIndex, lastIndex);

  return (
    <React.Fragment>
      {close ? (
        <Alert mt={2} onClose={() => setClose(false)} mb={1} severity="success">
          {response}
        </Alert>
      ) : error ? (
        <Alert mt={2} onClose={() => setClose(false)} mb={1} severity="warning">
          {error}
        </Alert>
      ) : null}
      <Card mt={0} mb={0}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Users List
          </Typography>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Frist Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicePost &&
                  slicePost.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.firstName}</TableCell>
                        <TableCell>{item.lastName}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>
                          <ProductsChip
                            size="small"
                            label={
                              (item.isActive === "true" && "Suspended") ||
                              (item.isActive === "false" && "Active")
                            }
                            color={
                              (item.isActive === "true" && "error") ||
                              (item.isActive === "false" && "success")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <div className="icons">
                            <Link to="/user/user-setting" state={item._id}>
                              <FaRegEdit />
                            </Link>
                            <span onClick={() => deleteLoad(item._id)}>
                              <FaTrash />
                            </span>
                            {item.isActive === "true" && (
                              <span onClick={() => undosuspend(item._id)}>
                                <FaToggleOn />
                              </span>
                            )}
                            {item.isActive === "false" && (
                              <span onClick={() => suspend(item._id)}>
                                <FaToggleOff />
                              </span>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableWrapper>
        </CardContent>
      </Card>
      <Pagination
        totalPosts={user}
        postPerPage={postPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPostPages={setPostPages}
      />
    </React.Fragment>
  );
}

function UserList() {
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        User Lists
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Dashboard</Typography>
        <Typography>User List</Typography>
        <Typography>list</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={24}>
          <Products />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserList;
