import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Profile from "./pages/pages/Profile";
import Settings from "./components/Settings";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import ChangePass from "./pages/auth/ChangePass";
import CheckOtpPage from "./pages/auth/CheckOtpPage";
import Loadboard from "./pages/pages/Loadboard";
import LoadCreate from "./pages/pages/LoadCreate";
import LoadUpdate from "./pages/pages/LoadUpdate";
import UserList from "./pages/pages/UserList";
import UserSettings from "./pages/pages/UserSettings";
import UpdateUser from "./pages/pages/UpdateUser";
import AdminProfile from "./pages/pages/AdminProfile";
import AdminSetting from "./pages/pages/AdminSetting";
import AdminLoginPage from "./pages/auth/AdminLoginPage";
import AdminSingupPage from "./pages/auth/AdminSingupPage";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Loadboard />,
      },
    ],
  },
  {
    path: "/user",
    element: <DashboardLayout />,
    children: [
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-setting",
        element: <UpdateUser />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <UserSettings />,
      },
    ],
  },
  {
    path: "/admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "settings",
        element: <AdminSetting />,
      },
      {
        path: "profile",
        element: <AdminProfile />,
      },
    ],
  },
  {
    path: "loadboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "/loadboard/create",
        element: <LoadCreate />,
      },
      {
        path: "/loadboard/edit",
        element: <LoadUpdate />,
      },
      {
        path: "/loadboard/create",
        element: <LoadCreate />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "change-password",
        element: <ChangePass />,
      },
      {
        path: "otp",
        element: <CheckOtpPage />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "admin-singup",
        element: <AdminSingupPage />,
      },
      {
        path: "admin-login",
        element: <AdminLoginPage />,
      },
    ],
  },
];

export default routes;
