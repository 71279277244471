import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function CheckOtp() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);
  const { resetPassword } = useAuth();
  const [response, setResponse] = useState();
  const [error, seterror] = useState();

  return (
    <Formik
      initialValues={{
        code: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required("Required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          resetPassword(
            await axios
              .post(
                "https://heaths-fa5b9965979a.herokuapp.com/user/otp",
                values
              )
              .then((res) => {
                setResponse(res.data.message);
                setTimeout(() => {
                  navigate("/auth/change-password");
                }, 3000);
              })
              .catch((err) => {
                seterror(err.response.data.message);
              })
          );
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {response ? (
            <Alert mt={2} mb={1} severity="success">
              {response}
            </Alert>
          ) : error ? (
            <Alert mt={2} mb={1} severity="warning">
              {error}
            </Alert>
          ) : null}
          <TextField
            type="text"
            name="code"
            label="code"
            value={values.code}
            error={Boolean(touched.code && errors.code)}
            fullWidth
            helperText={touched.code && errors.code}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Confirm
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default CheckOtp;
