import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import Style from "../../utils/css/Loadboard.module.css";
import useAuth from "../../hooks/useAuth";
import { Avatar, Paper, Typography } from "@mui/material";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function LoadCreate() {
  const navigate = useNavigate();

  //   useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       navigate("/");
  //     }
  //   }, [navigate]);

  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const { loads } = useAuth();

  return (
    <Wrapper sx={{ width: "40%" }}>
      <h2>Create your load</h2>
      <Formik
        initialValues={{
          origin: "",
          destination: "",
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          overhang: 0,
        }}
        validationSchema={Yup.object().shape({
          origin: Yup.string().required("First name is required"),
          destination: Yup.string().required("Last name is required"),
          length: Yup.string().required("Last name is required"),
          width: Yup.string().required("Last name is required"),
          height: Yup.string().required("Last name is required"),
          weight: Yup.string().required("Last name is required"),
          overhang: Yup.string().required("Last name is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await loads(
              await axios
                .post("https://heaths-fa5b9965979a.herokuapp.com/load", values)
                .then((res) => {
                  setResponse(res.data.message);
                  setTimeout(() => {
                    navigate("/loadboard");
                  }, 3000);
                })
                .catch((err) => {
                  seterror(err.response.data.message);
                })
            );
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {response ? (
              <Alert mt={2} mb={1} severity="success">
                {response}
              </Alert>
            ) : error ? (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            ) : null}
            <TextField
              type="text"
              name="origin"
              label="Origin"
              value={values.origin}
              error={Boolean(touched.origin && errors.origin)}
              fullWidth
              helperText={touched.origin && errors.origin}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="destination"
              label="Destination"
              value={values.destination}
              error={Boolean(touched.destination && errors.destination)}
              fullWidth
              helperText={touched.destination && errors.destination}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <h3>Dimensions</h3>
            <TextField
              type="number"
              name="length"
              label="Length"
              value={values.length}
              error={Boolean(touched.length && errors.length)}
              fullWidth
              helperText={touched.length && errors.length}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="width"
              label="Width"
              value={values.width}
              error={Boolean(touched.width && errors.width)}
              fullWidth
              helperText={touched.width && errors.width}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="height"
              label="Height"
              value={values.height}
              error={Boolean(touched.height && errors.height)}
              fullWidth
              helperText={touched.height && errors.height}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="weight"
              label="Weight"
              value={values.weight}
              error={Boolean(touched.weight && errors.weight)}
              fullWidth
              helperText={touched.weight && errors.weight}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="overhang"
              label="Overhang"
              value={values.overhang}
              error={Boolean(touched.overhang && errors.overhang)}
              fullWidth
              helperText={touched.overhang && errors.overhang}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Create Load
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default LoadCreate;
