import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

function Private() {
  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const { setting } = useAuth();
  const location = useLocation();
  const id = location.state;
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [changeshowPassword, setchangeshowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const changehandleClickShowPassword = () =>
    setchangeshowPassword((show) => !show);
  const changehandleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    axios
      .get(`https://heaths-fa5b9965979a.herokuapp.com/user/${id}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        seterror(err.response.data.message);
      });
  }, [id]);
  return (
    <Card
      mb={6}
      style={{ padding: "50px", boxSizing: "border-box", width: "40%" }}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          address: "",
          password: "",
          ConfirmPassword: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255).required("First name is required"),
          lastName: Yup.string().max(255).required("Last name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          phoneNumber: Yup.number().required("Phone Number is required"),
          address: Yup.string().max(255).required("Address is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await setting(
              await axios
                .put(
                  `https://heaths-fa5b9965979a.herokuapp.com/user/${id}`,
                  values
                )
                .then((res) => {
                  setResponse(res.data.message);
                  setLoading(false);
                })
                .catch((err) => {
                  seterror(err.response.data.message);
                  setLoading(false);
                })
            );
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {response ? (
              <Alert mt={2} mb={1} severity="success">
                {response}
              </Alert>
            ) : error ? (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            ) : null}
            <TextField
              type="text"
              name="firstName"
              label={user.firstName || ""}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="lastName"
              label={user.lastName || ""}
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              name="email"
              label={user.email || ""}
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="phoneNumber"
              label={user.phoneNumber || ""}
              value={values.phoneNumber}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              fullWidth
              helperText={touched.phoneNumber && errors.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="address"
              label={user.address || ""}
              value={values.address}
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={values.password}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirmPassword">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirmPassword"
                type={changeshowPassword ? "text" : "password"}
                name="ConfirmPassword"
                label="Confirm Password"
                value={values.ConfirmPassword}
                required
                fullWidth
                helperText={touched.ConfirmPassword && errors.ConfirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirmPassword visibility"
                      onClick={changehandleClickShowPassword}
                      onMouseDown={changehandleMouseDownPassword}
                      edge="end"
                    >
                      {changeshowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {loading ? "Loading..." : "Save Setting"}
            </Button>
          </form>
        )}
      </Formik>
    </Card>
  );
}

function UpdateUser() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Update User
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Dashboard</Typography>
        <Typography>Update User</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateUser;
