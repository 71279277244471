import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function AdminSingup() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .min(12, "Must be at least 12 characters")
            .max(255)
            .required("Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await signUp(
              await axios
                .post("https://heaths-fa5b9965979a.herokuapp.com/admin", values)
                .then((res) => {
                  setResponse(res.data.message);
                  setLoading(false);
                  setTimeout(() => {
                    navigate("/auth/admin-login");
                  }, 3000);
                })
                .catch((err) => {
                  seterror(err.response.data.message);
                  setLoading(false);
                })
            );
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {response ? (
              <Alert mt={2} mb={1} severity="success">
                {response}
              </Alert>
            ) : error ? (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            ) : null}

            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: "20px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {loading ? "Loading..." : "Sign up"}
            </Button>
            <Button
              component={Link}
              to="/auth/admin-login"
              fullWidth
              color="primary"
            >
              Admin Singin
            </Button>
            <Button
              component={Link}
              to="/auth/sign-in"
              fullWidth
              color="primary"
            >
              User Singin
            </Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default AdminSingup;
