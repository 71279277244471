import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);
const Centered = styled.div`
  text-align: center;
`;
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

function Details() {
  const [error, seterror] = useState();
  const id = localStorage.getItem("id");
  const [user, setUser] = useState([]);
  useEffect(() => {
    axios
      .get(`https://heaths-fa5b9965979a.herokuapp.com/admin/${id}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        seterror(err.response.data.message);
      });
  }, [id]);
  return (
    <Card mb={6}>
      {error && <h2>{error}</h2>}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Details
        </Typography>
        <Spacer mb={4} />
        <Centered>
          <Avatar alt="Lucy Lavender" src="" sx={{ mb: 5 }} />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightRegular">{user.email}</Box>
          </Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}

function AdminProfile() {
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        Profile
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Dashboard</Typography>
        <Typography>Admin Profiles</Typography>
        <Typography>Profile</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <Details />
        </Grid>
        <Grid item xs={12} lg={12} xl={9}>
          {/* <About /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdminProfile;
