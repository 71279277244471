import { List, User, Settings, Sliders } from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: List,
    title: "Loadboard",
  },
  {
    href: "/admin/profile",
    icon: User,
    title: "Profile",
  },
  {
    href: "/admin/settings",
    icon: Settings,
    title: "Settings",
  },
  {
    href: "/user/user-list",
    icon: Sliders,
    title: "User List",
  },
];

const UserDashboard = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default UserDashboard;
