import React, { useEffect, useState } from "react";
import Style from "../../utils/css/Loadboard.module.css";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Alert as MuiAlert, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw);
`;

const LoadItems = () => {
  const [loads, setLoads] = useState([]);
  const [error, setError] = useState();
  const [response, setResponse] = useState();
  const [close, setClose] = useState(false);
  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [postPages, setPostPages] = useState(7);
  const getLoads = async () => {
    await axios
      .get("https://heaths-fa5b9965979a.herokuapp.com/load")
      .then((res) => setLoads(res.data))
      .catch((err) => setError(err.response.data.message));
  };
  const deleteLoad = async (id) => {
    await axios
      .delete(`https://heaths-fa5b9965979a.herokuapp.com/load/${id}`)
      .then((res) => {
        setResponse(res.data.message);
        getLoads();
        setClose(true);
      })
      .catch((err) => setError(err.response.data.message));
  };

  useEffect(() => {
    getLoads();
  }, []);

  const lastIndex = currentPage * postPages;
  const firstIndex = lastIndex - postPages;
  const slicePost = loads.slice(firstIndex, lastIndex);

  return (
    <React.Fragment>
      <div>
        {close ? (
          <Alert
            onClose={() => setClose(false)}
            mt={2}
            mb={1}
            severity="success"
          >
            {response}
          </Alert>
        ) : error ? (
          <Alert
            mt={2}
            mb={1}
            onClose={() => setClose(false)}
            severity="warning"
          >
            {error}
          </Alert>
        ) : null}
      </div>
      <Card mt={0} mb={0} className={Style.bodyBar}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Users List
          </Typography>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>List NO</TableCell>
                  <TableCell>Origin</TableCell>
                  <TableCell>Destination</TableCell>
                  <TableCell>Dimensions</TableCell>
                  {!token && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {slicePost &&
                  slicePost.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index}</TableCell>
                        <TableCell>{item.origin}</TableCell>
                        <TableCell>{item.destination}</TableCell>
                        <TableCell>
                          <div className={Style.dimenList}>
                            <Typography>Length : {item.length}</Typography>
                            <Typography>Width : {item.width}</Typography>
                            <Typography>Height: {item.height}</Typography>
                            <Typography>Weight : {item.weight}</Typography>
                            <Typography>Overhang : {item.overhang}</Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="icons">
                            {!token && (
                              <span>
                                <Link to="/loadboard/edit" state={item._id}>
                                  <FaRegEdit />
                                </Link>
                                <span onClick={() => deleteLoad(item._id)}>
                                  <FaTrash />
                                </span>
                              </span>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableWrapper>
          <Pagination
            totalPosts={loads}
            postPerPage={postPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPostPages={setPostPages}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

function Loadboard() {
  const token = localStorage.getItem("token");
  return (
    <React.Fragment>
      <Helmet title="Profile" />
      <div className={Style.headerArea}>
        <Typography variant="h3" gutterBottom display="inline">
          Loadboard
        </Typography>
        {!token && (
          <Button
            component={Link}
            to="/loadboard/create"
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        )}
      </div>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Loadboard</Typography>
        <Typography>Lists</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={24}>
          <LoadItems />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Loadboard;
