import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useAuth from "../../hooks/useAuth";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [changeshowPassword, setchangeshowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const changehandleClickShowPassword = () =>
    setchangeshowPassword((show) => !show);
  const changehandleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          address: "",
          ConfirmPassword: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255).required("First name is required"),
          lastName: Yup.string().max(255).required("Last name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          phoneNumber: Yup.number().required("Phone Number is required"),
          ConfirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Required"),
          address: Yup.string().max(255).required("Address is required"),
          password: Yup.string()
            .min(12, "Must be at least 12 characters")
            .max(255)
            .required("Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await signUp(
              await axios
                .post("https://heaths-fa5b9965979a.herokuapp.com/user", values)
                .then((res) => {
                  setResponse(res.data.message);
                  setLoading(false);
                  setTimeout(() => {
                    navigate("/auth/sign-in");
                  }, 3000);
                })
                .catch((err) => {
                  seterror(err.response.data.message);
                  setLoading(false);
                })
            );
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {response ? (
              <Alert mt={2} mb={1} severity="success">
                {response}
              </Alert>
            ) : error ? (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            ) : null}
            <TextField
              type="text"
              name="firstName"
              label="First Name"
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last Name"
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="number"
              name="phoneNumber"
              label="Phone Number"
              value={values.phoneNumber}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              fullWidth
              helperText={touched.phoneNumber && errors.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="address"
              label="Address"
              value={values.address}
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirmPassword">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirmPassword"
                type={changeshowPassword ? "text" : "password"}
                name="ConfirmPassword"
                label="Confirm Password"
                value={values.ConfirmPassword}
                error={Boolean(
                  touched.ConfirmPassword && errors.ConfirmPassword
                )}
                required
                fullWidth
                helperText={touched.ConfirmPassword && errors.ConfirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirmPassword visibility"
                      onClick={changehandleClickShowPassword}
                      onMouseDown={changehandleMouseDownPassword}
                      edge="end"
                    >
                      {changeshowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {loading ? "Loading..." : "Sign UP"}
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                paddingTop: "10px",
              }}
            >
              <Typography>Already have an account?</Typography>
              <Button
                component={Link}
                to="/auth/sign-in"
                color="primary"
                style={{ minWidth: "0px", padding: "0px" }}
              >
                Sign In
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default SignUp;
