import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);
  const { resetPassword } = useAuth();
  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const [user, setUser] = useState([]);
  const [userEmail, setuserEmail] = useState();

  useEffect(() => {
    axios
      .get("https://heaths-fa5b9965979a.herokuapp.com/user")
      .then((res) => setUser(res.data))
      .catch((err) => seterror(err.data.response.message));
    if (userEmail) {
      const id = user.filter((item) => item.email === userEmail);
      localStorage.setItem("id", id[0]._id);
    }
  }, [userEmail, user]);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          resetPassword(
            await axios
              .post(
                "https://heaths-fa5b9965979a.herokuapp.com/user/reset",
                values
              )
              .then((res) => {
                setResponse(res.data.message);
                setuserEmail(values.email);

                // setTimeout(() => {
                //   navigate("/auth/otp");
                // }, 3000);
              })
              .catch((err) => {
                seterror(err.response.data.message);
              })
          );
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {response ? (
            <Alert mt={2} mb={1} severity="success">
              {response}
            </Alert>
          ) : error ? (
            <Alert mt={2} mb={1} severity="warning">
              {error}
            </Alert>
          ) : null}
          {!response ? (
            <>
              <TextField
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Send Email
              </Button>
            </>
          ) : (
            <Link to="/auth/otp">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Click Here to Reset Code
              </Button>
            </Link>
          )}
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
