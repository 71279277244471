import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);
  const { signIn } = useAuth();
  const [response, setResponse] = useState();
  const [error, seterror] = useState();
  const [suspend, setSuspend] = useState();
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await signIn(
              await axios
                .post(
                  "https://heaths-fa5b9965979a.herokuapp.com/user/login",
                  values
                )
                .then((res) => {
                  const isactive = res.data.user.isActive;
                  setLoading(false);
                  if (isactive === "true") {
                    setSuspend("Your account has been Suspended");
                  } else {
                    setResponse(res.data.message);
                    localStorage.setItem("email", values.email);
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("id", res.data.user._id);
                    setTimeout(() => {
                      navigate("/");
                    }, 3000);
                  }
                })
                .catch((err) => {
                  seterror(err.response.data.message);
                  setLoading(false);
                })
            );

            navigate("/private");
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {response ? (
              <Alert mt={2} mb={1} severity="success">
                {response}
              </Alert>
            ) : error ? (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            ) : suspend ? (
              <Alert mt={2} mb={1} severity="warning">
                {suspend}
              </Alert>
            ) : null}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                component={Link}
                to="/auth/reset-password"
                color="primary"
              >
                Forgot Password?
              </Button>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
            <span>
              <Button
                component={Link}
                to="/auth/admin-login"
                fullWidth
                color="primary"
              >
                Admin Login
              </Button>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                paddingTop: "5px",
              }}
            >
              <Typography>Don't have an account?</Typography>
              <Button
                component={Link}
                to="/auth/sign-up"
                color="primary"
                style={{ minWidth: "0px", padding: "0px" }}
              >
                Sign Up
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default SignIn;
